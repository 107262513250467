import React from "react"
import Layout from "../components/layout"
import { Element } from "react-scroll"
import SEO from "../components/seo"
import InternalLink from "../components/internal-link"

const Tasks = ({ data }) => (
  <Layout title="Tasks for 2022-23" color="blue" heroImageName="tasks-2022">
    <SEO title="Tasks for 2022-23" ogImage="tasks-2022" />

    <div className="sidebar">
      <h2>Outline</h2>
      <ul>
        <li>
          <InternalLink to="1. Preamble">1. Preamble</InternalLink>
        </li>
        <li>
          <InternalLink to="2. Building Power in the Workplace">
            2. Building Power in the Workplace
          </InternalLink>
        </li>
        <li>
          <InternalLink to="3. Towards a Working-Class Party">
            3. Towards a Working-Class Party
          </InternalLink>
        </li>
        <li>
          <InternalLink to="4. Mass Action for Democracy and Social Justice">
            4. Mass Action for Democracy and Social Justice
          </InternalLink>
        </li>
        <li>
          <InternalLink to="5. Inter-imperialist Rivalry and Socialist Internationalism">
            5. Inter-imperialist Rivalry and Socialist Internationalism
          </InternalLink>
        </li>
        <li>
          <InternalLink to="6. Building a Democratic DSA">
            6. Building a Democratic DSA
          </InternalLink>
        </li>
        <li>
          <InternalLink to="7. Organizing a Democratic Socialist Student Movement">
            7. Organizing a Democratic Socialist Student Movement
          </InternalLink>
        </li>
      </ul>
    </div>

    <div className="content" id="tandp">
      <p>
        What are the conditions facing the global working class? How can
        socialists in the U.S. best help build its power?
      </p>
      <p>
        Those questions are what Bread &amp; Roses attempts to answer in our
        Tasks &amp; Perspectives for 2022-2023. After months of discussion in
        advance, Bread &amp; Roses members met in Jersey City, New Jersey in
        July 2022 for a three-day convention that debated and adopted this
        document.
      </p>
      <p>
        We hope that by putting forward these Tasks &amp; Perspectives, we
        encourage other activists and tendencies on the U.S. left to put forward
        their own perspectives and ideas for tasks. Open strategic debate is a
        crucial foundation for a stronger, more inclusive left.
      </p>
      <Element name="1. Preamble" id="1. Preamble">
        <div className="h-wrapper one">
          <h2>1. Preamble</h2>
          <div className="header-bg"></div>
        </div>
        <div className="focus">
          <p>
            Climate change, pandemic, recession, war, and a right-wing assault
            on democracy. These crises of capitalist political and economic
            stability foreshadow a new barbarism. Yet socialists today are also
            presented with new openings for resistance.
          </p>
        </div>
        <p>
          Since 2021, U.S. inflation has soared to a current annual high of
          8.6%, undermining the nominal wage gains workers obtained during the
          pandemic. Though labor markets continue to empower workers,
          governments are now raising interest rates with the goal of creating
          another global recession. Another recession would throw millions into
          poverty, reversing the labor market dynamics that have encouraged
          class militancy in the U.S. Even in the absence of a recession,
          advanced capitalist states will remain locked into low economic
          growth, high inequality, and recurrent economic downturns.
        </p>
        <div className="img-wrapper">
          <img src={"/brandon-2.jpg"} alt="Joe Biden" />
        </div>
        <p>
          In Ukraine, a new major war hints at a new era of regional imperialist
          conflicts and geopolitical instability. Without a stronger socialist
          movement, the escalation of inter-imperialist rivalries from 2022-2024
          will allow capitalists and their political proxies to turn popular
          disaffection into nationalist, racist, and reactionary directions.
        </p>
        <p>
          In the United States, the Republican Party ramps up its attack on
          political, civil, and individual liberties; the Supreme Court’s
          assault on abortion access and voting rights is only the beginning.
          The likely victories of the GOP in 2022 and 2024 will push this
          terrifying process further. Yet the Democratic Party has not been up
          to the task of either mobilizing to defend our rights or advancing a
          meaningful reform agenda that would undermine the electoral support
          for right-wing populists. Without a strong and independent left, the
          main beneficiary of the collapsing center is sure to be the extreme
          right.
        </p>
        <p>
          And looming large over all of this is the shadow of climate
          catastrophe. We are now living through the opening years of heat
          waves, megastorms, species extinction, and more. Prospects for the
          near future look bleak, as the world’s ruling class whittles away our
          final chance to avert the worst of the coming catastrophe. The era we
          are entering now will be defined by this crisis above all others.
        </p>
        <p>
          There are still some reasons for optimism. Left parties have won
          national elections or are likely to soon do so in several major Latin
          American countries in what could become a second “Pink Tide.”
          Socialists in the U.S. can gain inspiration and valuable lessons from
          these experiences.
        </p>
        <p>
          Closer to home, signs of a newly militant working-class are also
          encouraging. New union wins in the U.S. at Starbucks and Amazon
          continue the rebirth of working-class consciousness and politics from
          Bernie Sanders’ 2016 campaign and the 2018-19 teacher strike wave.
          Millions participated in the second Black Lives Matter upsurge in
          2020, and Democrats’ inaction in defense of abortion and voting rights
          is leading more and more people to embrace an oppositional mass
          politics. While the failing center of U.S. politics has spurred the
          growth of the far right, we are encouraged by the election of
          socialist politicians to local and state offices across the country,
          and the radicalization of millions of young people.
        </p>
        <div className="pullquote">
          <p>
            “Socialists have a historic role to play in making the most of these
            openings.”
          </p>
        </div>
        <p>
          Socialists have a historic role to play in making the most of these
          openings. Left to its own devices, the existing leadership of the U.S.
          labor movement will not be able or willing to organize the newly
          radical consciousness into a union resurgence. As they did in the 20th
          century, radicals must help organize within the unions to build a
          militant, left-wing, and rank-and-file driven labor movement. As in
          the unions, socialists can help build fighting social movement
          organizations whose power flows from masses of ordinary people,
          movements that refuse to subordinate themselves to the Democrats’
          fixation on legislative propriety and electoral moderation.
        </p>
        <p>
          The left must also lead a process of bringing together progressive
          movements and political organizations into something greater than the
          sum of its parts and capable of offering an alternative to the
          declining center: first a broad political organization, and ultimately
          a new political party of and for the working class. Amidst the crises
          of capitalist economics and the bankruptcy of liberal politics,
          socialists have an opportunity to promote an anti-capitalist critique
          with mass resonance.
        </p>
        <p>
          To do that, we’ll need to overcome the challenges facing DSA and
          relaunch the momentum that defined the socialist left from 2016 to
          2020. Since the end of the 2020 Sanders campaign and the decline of
          the Black Lives Matter movement, political disorientation and the lack
          of democratic oversight over the national director have too often left
          socialists on the sidelines of key conflicts. Now is the time to put
          forward democratic socialism as the humane alternative to the barbaric
          future of capitalism.
        </p>
      </Element>
      <Element
        name="2. Building Power in the Workplace"
        id="2. Building Power in the Workplace"
      >
        <div className="h-wrapper two">
          <h2>2. Building Power in the Workplace</h2>
        </div>
        <h3>2.1 Labor Perspectives</h3>
        <div className="focus">
          <p>
            Despite the continued decline of union membership in the U.S., we
            are living through an upsurge in pro-labor sentiment. Approval of
            labor unions is at a 57-year high and a clear majority of American
            workers say that they would like to see a union at their own jobs.
          </p>
        </div>
        <div>
          <p>
            And not only is the general public in a favorable mood towards
            workers and unions, the U.S. labor movement is experiencing a
            revival of militancy in unexpected places.
          </p>
          <p>
            Rising worker militancy has its roots in the pandemic, deep
            organizing by the militant minority, and the rebirth of the
            socialist movement. The pandemic showed us that frontline workers
            were disposable from the point of view of the ruling class, but it
            also showed us how much power we truly have. The militant minority
            has slowly been building itself for decades supported by projects
            like Labor Notes. It played an indispensable role in bringing
            together like-minded workers within their unions and across their
            unions. When reformers win power, as in the Teamsters and in many
            teacher locals, they are set up to play a leading role in the years
            to come. And the emerging socialist movement has brought newfound
            militancy to the labor movement.
          </p>
          <div className="img-wrapper">
            <img src={"/uaw.jpg"} alt="UAW strike" />
          </div>
          <p>
            The future is promising. A contract campaign at UPS — where 340,000
            workers could go on strike in 2023 — could set an example for how to
            take on employers and is a good opportunity for DSA chapters to do
            serious strike support. The Amazon Labor Union opened eyes about how
            to organize in unorganized shops and its win energized thousands.
            And Starbucks Workers United is bringing unionism to a new
            generation.
          </p>
          <div className="pullquote">
            <p>
              “We believe strongly that the decisive force in the coming period
              will be the power of organized rank-and-file activists.”
            </p>
          </div>
          <p>
            We believe strongly that the decisive force in the coming period
            will be the power of organized rank-and-file activists. Many union
            leaders today remain wedded to conservative business unionism and
            are trapped playing a subservient role to the Democratic Party. It’s
            the task of democratic socialists in the labor movement to link up
            with the militant minority of workers who want to organize to fight
            their bosses and push for a new class struggle unionism — one that
            fights to democratize the labor movement, put it on a fighting
            footing to take on corporate power, and champion allied causes that
            build working-class power broadly. Rebuilding a fighting,
            rank-and-file led, democratic labor movement is at the core of our
            strategy to build a democratic socialist future in the United
            States.
          </p>
          <h4>2.1.1 DSA and the Labor Movement</h4>
          <p>
            DSA has contributed to this labor upsurge where it can, including by
            supporting the Emergency Workplace Organizing Committee (EWOC)
            alongside the United Electrical workers (UE), the Logistics
            Committee organizing, and the Solidarity is Brewing campaign to
            support organizing at Starbucks. DSA chapters have also supported
            local strikes when they arise, sometimes playing important roles in
            supporting workers’ struggle.
          </p>
          <p>
            Nevertheless, challenges loom on the horizon. Socialists lack
            capacity to carry out all the tasks before us in the labor movement.
            Experienced comrades and cadre must balance leadership roles that
            they have in their local DSA chapters, the organizing they do within
            their unions, and the day-to-day labor they provide as workers.
          </p>
          <p>
            Lack of skills and enthusiasm has led to inconsistency in our labor
            work. In order to expand the numbers of people who can participate
            in the vital work of rank-and-file organizing, we must build more
            structured and systematic programs for leadership development and
            political education. More broadly, we have not yet won over all of
            our comrades to our vision of mass action and rank-and-file power
            from below. Limitations on DSA's labor work have also been
            aggravated by a lack of political will and interest when staff time
            is allocated.
          </p>
          <h3>2.2 Labor Tasks</h3>
          <p>
            The key tasks for democratic socialists in the labor movement
            include: 1) supporting new organizing, union reform, and contract
            negotiation struggles, 2) building out industry groups of
            rank-and-file organizers, 3) boosting direct struggle against the
            boss on the shop floor, and 4) connecting workplace militancy to
            political action.
          </p>
          <p>
            We look eagerly towards the coming contract fights and new
            organizing battles at Amazon, Starbucks, UPS, and beyond. These big
            battles that pit workers against their profit-hungry (and
            highly-profitable) companies will define the class struggle in the
            next two years. Democratic socialists’ place is in these fights and
            in others in the education and healthcare sectors.
          </p>
          <p>
            Additionally, democratic socialists should encourage fellow
            socialists to take rank-and-file union jobs and work toward building
            networks or organizations within their unions or industries.
          </p>
          <p>
            Militant, democratic, bottom-up struggles on the shop floor are
            essential for building working-class consciousness and organization.
            The power of the labor movement flows from the strength of
            shop-floor organization and shop-floor struggle that changes worker
            consciousness. These struggles help workers learn that they are the
            union — not the officers or the staff — and that they hold the
            power. Socialists prioritize creating the conditions for these
            struggles in their workplaces by developing workplace leaders and
            helping to organize fights against the boss. This is how we build a
            militant minority.
          </p>
          <div className="pullquote">
            <p>
              “The global crises workers face today — climate change, public
              health, democratic backsliding and the stripping of civil rights
              including abortion — cannot be solved without worker power.”
            </p>
          </div>
          <p>
            Socialists should support and build the campaigns of DSA Labor, like
            national and chapter efforts around labor solidarity and logistics
            organizing. We should build Labor Notes, by organizing local
            Troublemakers Schools and trainings, bringing coworkers to
            conferences and online trainings, getting coworkers to volunteer for
            Labor Notes’ new outreach program, and pitching stories about local
            labor struggles. Socialists should prioritize organizing with
            rank-and-file caucuses like UAWD, TDU, UCORE, and others yet to be
            built. We should help workers build new organization through EWOC.
            And socialists should build projects to recruit and mentor young
            people to take union jobs, develop industry groups to strategize,
            lead strike solidarity projects, and other tasks that further the
            rank-and-file strategy.
          </p>
          <p>
            The global crises workers face today — climate change, public
            health, democratic backsliding and the stripping of civil rights
            including abortion — cannot be solved without worker power. Workers
            at the point of production in energy, building trades, logistics,
            and healthcare can play a fundamental role in leading the fight to
            address these crises. Our immediate task is to build and cohere
            militant workplace action on the shop floor and movements to take
            back our unions. Workers in motion who are connected with each other
            in union reform movements and in the broader left wing of labor,
            including Labor Notes, are positioned to join with political
            movements to win our classwide demands. And, in the long term, it's
            these forces that will transform the labor movement into one that
            leads the transition to a socialist society.
          </p>
          <p>
            The prospects for labor are bright. They give us hope that a better
            future can be won — and that the end of labor’s multi-decade decline
            is in sight. We call on our comrades in the socialist movement to go
            all in on building a militant, democratic, and left-wing labor
            movement.
          </p>
        </div>
      </Element>
      <Element
        name="3. Towards a Working-Class Party"
        id="3. Towards a Working-Class Party"
      >
        <div className="h-wrapper three">
          <h2>3. Towards a Working-Class Party</h2>
        </div>
        <h3>3.1 Electoral Perspectives</h3>
        <div className="focus">
          <p>
            As socialists, we believe that the contest for state power is
            essential to the organization and consciousness of the working
            class, and that the state will have a key role to play in
            constructing a democratic socialist society. Outside pressure on
            politicians is not enough; socialists need to win elections and
            fight within the political system to support mass movements for
            social transformation. Since 2016, insurgent socialist campaigns for
            office have politicized millions and expanded the organized
            socialist left by the tens of thousands. Although the significant
            electoral wins of the socialist left and DSA represent a crucial
            starting point for building a mass workers’ party independent of the
            two capitalist parties in the United States, such a goal remains
            distant.
          </p>
        </div>
        <p>
          We remain committed as ever to independent political action,
          organizing separate from and against the Democratic Party leadership.
          Working-class political independence is all the more crucial as the
          Democrats sideline any pretense of reformism and double down on the
          strategy of prioritizing high-income professional voters, while trying
          to lock down working-class votes through the institutionalized
          blackmail of the two-party system and a minimalist defense of the
          status quo.
        </p>
        <h4>3.1.1 DSA and Electoral Politics</h4>
        <p>
          Since our caucus’ founding in 2019, the socialist left has accumulated
          a wealth of experiences that allow us to combine theory and practice.
          DSA rallied behind Bernie Sanders’ unsuccessful 2020 Democratic
          presidential primary campaign, while DSA-endorsed candidates and
          members continue to win elections at the national, state, and local
          level, typically by running in primaries against centrist Democrats or
          in nonpartisan races.
        </p>
        <div className="img-wrapper">
          <img src={"/bernie-3.jpg"} alt="Bernie" />
        </div>
        <p>
          However, the socialist left has also experienced considerable
          challenges to our electoral strategy since 2019. While they deserve
          credit for taking principled, oppositional stances, members of the
          “Squad” like Alexandria Ocasio-Cortez and other self-identifying
          socialists frequently defer to the Democratic Party hierarchy and seem
          to have no intention of building independent political organization.
          This stance has not resulted in significant concessions from the
          Democrats or the capitalist class. To the contrary, at the national
          level, progressive priorities like Medicare for All remain distant
          possibilities, while the Democrats continue to allow their right wing
          to obstruct most reforms.
        </p>
        <p>
          Meanwhile, for all its importance, the electoral project of DSA and
          its National Electoral Committee remains incomplete; neither the
          national body nor the NEC have played significant roles in DSA
          chapters’ local electoral successes, and the electoral priority that
          DSA voted on at its last convention remains a dead letter. Electoral
          strategy continues to be made at the chapter level, while meaningful
          logistical and financial support for chapters from the NEC is rare.
          Last, socialists in office often lack connections to an organized mass
          base outside DSA that could allow them to successfully resist the
          pressures of capitalists and their political proxies.
        </p>
        <h3>3.2 Electoral Tasks</h3>
        <p>
          How should socialists overcome the limitations of our electoral
          approach?
        </p>
        <p>
          In order to combat the tendency of socialist electeds to defer to the
          superior forces of the Democratic Party, Bread & Roses proposes to
          prioritize building class-struggle electoral campaigns and recruiting
          class-struggle candidates — socialist cadre who have a history of
          movement organizing, strong links to working-class organizations, and
          understand and unequivocally support working-class opposition to
          capitalist priorities. We should expect these candidates to take a
          confrontational approach toward the Democratic Party establishment,
          while running and once in office.
        </p>
        <div className="pullquote">
          <p>
            “We need to use our elected offices to build the power of
            rank-and-file led unions, to recruit organic leaders in key
            industries, and to organize strike support.”
          </p>
        </div>
        <p>
          Second, the socialist left should focus on cohering citywide and
          statewide caucuses of elected socialists as in Chicago and NYC, as
          well as broader progressive formations with a working-class base (like
          the Richmond Progressive Alliance in California). Socialists within
          these projects should push to orient them towards building a mass
          party for the working class. Towards this end, DSAers should create a
          stronger culture of democratic decision-making around electoral and
          legislative work, build more cohesive statewide DSA collaboration, and
          start running some candidates independent of the Democratic Party
          ballot line where it makes sense. We also continue to advocate for
          reform of DSA’s National Electoral Committee, so that national
          endorsements involve real resource commitments (especially money and
          staff time).
        </p>
        <p>
          Third, the socialist left should work towards deeper and more
          intentional connections between socialists in office and working-class
          constituencies in unions and workers’ organizations. The rank-and-file
          strategy will play a key role in building a political base among
          militant workers, winning union leadership, and breaking unions’
          subservience to the corporate-backed Democratic Party. Socialists
          should develop programs at the local level for rank-and-file DSA
          members to organize their unions to put forward union members as
          candidates. And we should ensure in turn that socialist officials use
          the full power of their offices to support their working-class
          constituencies and help convince union members of the need for a new
          party representing the working class. We need to use our elected
          offices to build the power of rank-and-file led unions, to recruit
          organic leaders in key industries, and to organize strike support.
        </p>
      </Element>
      <Element
        name="4. Mass Action for Democracy and Social Justice"
        id="4. Mass Action for Democracy and Social Justice"
      >
        <div className="h-wrapper four">
          <h2>4. Mass Action for Democracy and Social Justice</h2>
        </div>
        <h3>4.1 Movement Perspectives</h3>
        <div className="focus">
          <p>
            The Supreme Court and Republican Party are launching an assault on
            American democracy and civil rights. While the majority of Americans
            oppose rolling back federal abortion protections, demand action on
            climate change, and want stricter gun legislation, the Democratic
            Party has been incapable of achieving progressive change. Democratic
            control of the White House and Congress since 2021 has done almost
            nothing to address our nation’s deep racial and economic
            inequalities or put up a serious fight to defend democracy and the
            climate. Millions of Americans are increasingly seeing the failure
            of the Democrats to protect them from the far right but have few
            options for what can be done in response.
          </p>
        </div>
        <p>
          Like much of the world, the U.S. has seen repeated mass protest
          upsurges since 2008, including Occupy Wall Street, Black Lives Matter
          in 2014 and 2020, climate marches including over six million people,
          student walkouts against gun violence and homophobic and transphobic
          laws, and recent protests to defend abortion rights. However, most of
          these protests have been fleeting and disorganized, and often lacked
          even basic demands or slogans. While they have had an enormous effect
          in changing consciousness — including putting many on the path to
          becoming socialists, joining DSA, and organizing unions — these
          protests have by and large accomplished little in either building
          permanent organization or achieving policy change. Decades of defeat
          and “horizontalist” or “anti-organization” ideas have left today’s
          movements without a healthy ecosystem of grassroots organizations or
          the skills to build fighting movements. Without a mass-action-oriented
          strategy and more durable organization, these movements have little
          prospects of winning progressive changes.
        </p>
        <div className="img-wrapper">
          <img src={"/abortion.jpg"} alt="abortion rally" />
        </div>
        <p>
          Where movement organizations do exist, they’re often top-down,
          foundation-funded, and staff-driven NGOs that claim the “turf” of
          different issues but leave little room for the initiative,
          participation, or leadership of the mass of ordinary people who care
          and want to fight back. On the other hand, a reborn DSA has used its
          large membership lists to run “DSA for…” campaigns in support of
          Medicare for All and the Green New Deal. But given the still-limited
          base of organized socialists and the NGO-style methods of DSA’s
          national organization, these campaigns have not evolved into mass
          struggles far beyond DSA’s membership. These limitations are
          exacerbated by the fact that relatively few DSA activists are
          experienced at organizing mass actions or protests or building social
          movement organizations. Compare this to the socialist movement of the
          1930s, where socialist cadre cut their teeth building mass labor and
          unemployed struggles during the Great Depression, or in the 1960s,
          where cadre were radicalized through participation in massive and
          disruptive Civil Rights campaigns like sit-ins, freedom rides, and
          boycotts.
        </p>
        <div className="pullquote">
          <p>
            “Socialists have often been the most dedicated and skilled fighters
            helping build mass movements for social and economic justice.”
          </p>
        </div>
        <p>
          Socialists have often been the most dedicated and skilled fighters
          helping build mass movements for social and economic justice.
          Foundation-funded nonprofits and Democratic Party–linked advocacy
          groups naturally pursue a self-defeating strategy of compromise and
          conciliation. Socialists’ job, instead, is to build movements that
          aren’t afraid of confrontation and that rely on the weapons of
          grassroots democracy and the mass action of ordinary people instead of
          polite and elite-centric lobbying.
        </p>
        <p>
          Absent mass, democratic, and broad organizations fighting for
          democracy and economic and social justice, the millions of
          working-class people of all ideological stripes touched by these
          issues have little prospect of building power and changing the world.
        </p>
        <h3>4.2 Movement Tasks</h3>
        <p>
          Today’s left needs to build broader social movements and movement
          organizations. This could mean rank-and-file DSA members joining
          existing movements fighting around issues like abortion or climate
          change, helping build their campaigns and organizations, and pushing
          them toward a grassroots, mass-action, and working-class strategy
          (instead of a top-down, technocratic, and elite-centric one). It could
          mean DSA joining with such organizations in local, statewide, or
          national issue-based coalitions. Or it could mean DSA members working
          to start new campaigns and new organizations that are broader than
          DSA. The important thing is for socialists to go beyond just
          organizing “DSA for…” campaigns and committees. We need to build mass
          organization and mass action toward social justice demands that
          attract and develop organizers who are not yet ready to self-identify
          as democratic socialists. DSA is of course an important hub for this
          work. It recruits, connects, and trains new socialists, supports these
          campaigns and coalitions, and develops orientations toward the mass
          movements we take part in.
        </p>
        <p>
          Socialist transformation is not on the immediate horizon, and
          movements today are at best fighting for more basic demands like
          abortion, racial justice, democratic freedoms, healthcare, climate
          action, workers’ rights, and rent control. DSA members should
          nevertheless work within movements to advance socialist ideas,
          especially among movement cadre who are becoming open to radical
          politics. Just as in the labor movement, these groups of people,
          already in motion around a mass struggle, are also prime recruits for
          building a strong socialist movement.
        </p>
      </Element>
      <Element
        name="5. Inter-imperialist Rivalry and Socialist Internationalism"
        id="3. Towards a Working-Class Party"
      >
        <div className="h-wrapper five">
          <h2>5. Inter-imperialist Rivalry and Socialist Internationalism</h2>
        </div>
        <h3>5.1 International Perspectives</h3>
        <div className="focus">
          <p>
            While the United States’ ruling class and political elite remain the
            dominant global power, the post-Cold War era of unipolarity is
            coming to a close. This geopolitical shift has vast and
            unpredictable consequences: although the ability of the U.S. to
            single handedly shape the world since 1989 has often been
            overstated, its military, economic, and cultural dominance have had
            immense influence. What we do know is that absent international
            solidarity movements, the cost of rising interimperial tensions will
            be borne by the global working class. As capitalism proves incapable
            of resolving the climate crisis and struggles for resources
            intensify, capitalists in powerful states will intensify the
            exploitation of the global South while seeking to divide and conquer
            workers with the tools of nationalism, war, attacks on immigrants,
            and the demonization of domestic anti-imperialist forces.
          </p>
        </div>
        <p>
          Our perspective on socialist internationalism focuses on learning from
          and connecting to working class-led movements, including activists in
          parties, unions, and social movements. This differentiates us from
          some other tendencies in DSA and some on the broader socialist left,
          who focus on building relationships with nominally left parties in
          office, with too little attention to their political or class
          character. Socialists cannot ignore the fact that struggles against
          foreign domination often result in contradictory alliances of workers
          and domestic elites; unless the working class has a leading role, the
          result is often greater exploitation by domestic capital. In order to
          understand the class nature of international struggles and where they
          might lead, we cannot uncritically rely on official relationships
          between leaders in DSA and established “left” political parties or on
          the official narratives of those parties. Rather, DSA and the broader
          U.S. left should directly connect to a broad spectrum of activists and
          anti-capitalist militants abroad in order to develop an
          internationalist perspective and movement.
        </p>
        <div className="pullquote">
          <p>
            “What we do know is that absent international solidarity movements,
            the cost of rising interimperial tensions will be borne by the
            global working class.”
          </p>
        </div>
        <p>
          Socialists are a long way from having the kind of power necessary to
          influence the foreign policy of the imperial core. While our lack of
          leverage should inform the demands we focus on, it should not serve as
          an excuse to ignore questions of U.S. foreign policy and our
          relationships with international left movements.
        </p>
        <h4>5.1.1 The Return of Interimperialist Rivalry</h4>
        <p>
          Increasing tensions between the U.S., China, and Russia are emblematic
          of the new period of interimperialist rivalry. Since at least Obama’s
          second term, U.S. elites have gone from viewing China primarily as a
          lucrative growing market and labor source to perceiving it as a
          potential economic challenger and regional security threat in
          Southeast Asia. Using hostile trade agreements, the U.S. has sought to
          isolate China. If the belligerent nationalism of the Trump presidency
          has partially subsided, tensions with China continue under Biden.
        </p>
        <p>
          Alongside this, the unjustifiable Russian invasion of Ukraine has once
          again brought nuclear powers into a deadly proxy war, where the only
          sure outcome is the devastation of Ukraine. The position of Ukraine in
          Europe has been a simmering tension since the collapse of the Soviet
          Union. At the behest of the U.S., NATO has gone out of its way to
          exclude Russia from an all-European security agreement, even when this
          posed risks to supposed European allies and made armed conflict more
          likely.
        </p>
        <p>
          Nevertheless, while we recognize the U.S.’s share of responsibility
          for the return of overt conflict between major states, we reject the
          position that regional powers like Russia and China, merely by virtue
          of being in opposition to the United States and its Western allies,
          constitute a progressive force in global politics. Vladimir Putin’s
          regime in Russia is an anchor of global reaction, offering support to
          extreme nationalist parties while articulating a conservative
          nationalism inspired by Russian Tsarism. Meanwhile, after taking the
          “capitalist road” in the 1970s under Deng Xiaoping, the Chinese
          Communist Party has recently adopted a neoauthoritarian personality
          cult under Xi Jinping, stamping out critical Marxists and incipient
          working-class insubordination. Though our primary task is to oppose
          U.S. imperialism, we should not apologize for these regimes. Our
          commitment to democratic socialism and anti-imperialism determines our
          stance: no to the imperialism of the U.S. and its allies — the
          dominant force in the world today — and no to Russian and Chinese
          imperialism, its major challengers.
        </p>
        <h4>5.1.2 A Second Pink Tide</h4>
        <p>
          After years on the defensive, progressive forces across South America
          have succeeded in pushing out right-wing governments. We’ve already
          seen the victories of Gabriel Boric’s government in Chile, Luis Arce
          in Bolivia, Gustavo Petro in Colombia, and Pedro Castillo in Peru.
          Hopefully, there will be a new Lula presidency in Brazil, accountable
          to both the PT and PSOL, center-left and left wing parties.
        </p>
        <div className="img-wrapper">
          <img src={"/lula.jpg"} alt="PSOL for Lula" />
        </div>
        <p>
          These developments are inspiring, but also require us to be sober in
          our expectations. In attempting to implement even moderate
          social-democratic reforms, they will be hemmed in by U.S. imperialism,
          the resistance of domestic elites and right-wing forces, the threat of
          capital flight, and the highly punitive global debt system. But
          socialists should also recognize that progressive leaders in these
          countries are not preparing for a break with capitalism — in many
          cases they’re actively trying to build alliances with capital. U.S.
          socialists should pay close attention to these developments both to
          learn lessons and discover avenues for supporting socialist forces in
          these countries.
        </p>
        <h4>5.1.3 So-called “Left-wing” Authoritarian Governments</h4>
        <p>
          In the name of resisting U.S. influence, some self-described left-wing
          governments have become increasingly undemocratic and hostile to their
          own working class and internal leftist critics. Such a process is
          tragic because there is truth to their claims that the U.S.
          consistently interferes in their domestic politics and violates their
          national sovereignty. But acknowledging this reality does not justify
          defense of the authoritarian policies of countries like Venezuela
          under Nicolás Maduro, Nicaragua under Daniel Ortega, and Cuba under
          Miguel Díaz-Canel. Socialists in the United States can oppose the
          imperialism of our ruling class without being apologists for
          authoritarianism abroad.
        </p>
        <p>
          And we know that a turn to authoritarianism is not the only way to
          fight back against U.S. imperialism. In Bolivia, the Movimiento al
          Socialismo resisted a U.S.-backed coup attempt without turning to
          authoritarian methods. And we saw this in Venezuela under Hugo Chavez,
          who remained committed throughout his life to a democratic transition
          to socialism.
        </p>
        <p>
          While we oppose anti-democratic policies, socialists’ primary task in
          the United States is to defend sovereign nations from American
          interference. We oppose all actual and threatened military actions and
          economic war in the form of sanctions, and we support ending the
          embargo on Cuba. The only way working people will be able to build
          more democratic societies is with the freedom to make their own
          choices, unencumbered by the constant threat of foreign aggression.
        </p>
        <h4>5.1.4 Palestine</h4>
        <p>
          As the primary ally of Israel, supplying it with immense military aid
          and protecting it from criticism, the U.S. government is second only
          to Israel itself in being responsible for the Israeli apartheid
          regime. Because it is within the power of the United States to force a
          change in Israeli policy towards Palestinians, support for Palestinian
          national liberation must be non-negotiable for U.S. socialist
          organizations and socialists in office.
        </p>
        <div className="img-wrapper">
          <img src={"/gaza.jpg"} alt="Gaza" />
        </div>
        <p>
          The Israeli lobby in the U.S. is extremely powerful. Calling into
          question the treatment of Palestinians threatens a huge number of
          interests, arms manufacturers who value the Israeli market not least
          among them. These groups take a maximalist approach to repress any
          critique of Israeli apartheid, causing most progressive and even
          socialist candidates to shy away from taking a stand. We believe that
          as a general rule DSA should not endorse politicians who do not accept
          the importance of the struggle for Palestinian national liberation.
          Rolling back the influence of Israel and its allies in U.S. politics
          will require a movement firmly committed to Palestinian
          self-determination.
        </p>
        <h4>5.1.5 Migration and International Labor Solidarity</h4>
        <p>
          International economic competition pits national working classes
          against one another. The loss of jobs and industry to foreign
          countries or domestic immigrants is a frequent issue of concern for
          U.S. and global workers. Socialists and workers’ organizations must
          oppose economic nationalism and xenophobia. Instead of appeals to
          economic nationalism or xenophobia, socialists must develop the
          democratic power and solidarity of working-class movements as the path
          to go beyond national competition.
        </p>
        <p>
          Socialists must fight in solidarity with all working-class immigrants,
          including advancing freedom of movement and freedom to stay. Migration
          across national borders will only grow as capitalism proves incapable
          of dealing with the climate crisis. As increasing disasters and forced
          migration risk dividing us, organized solidarity between international
          working people will be especially important to protect people fleeing
          war, natural disasters, and economic devastation.
        </p>
        <h3>5.2 International Tasks</h3>
        <p>
          Though we currently lack the power to change U.S. foreign policy,
          socialists have two important internationalist tasks in the United
          States:
        </p>
        <p>
          1. Develop and maintain relationships with working-class movements
          abroad. Socialists should develop direct political relationships with
          organic leaders and rank-and-file participants in international
          working-class movements, including bilateral relationships with
          leftists in parties and movements abroad, both through DSA’s
          International Committee, and independently of it where that makes
          sense. For example, it may make more sense for DSA to develop
          relationships with broad left parties and for tendencies within DSA to
          develop relationships with tendencies inside other parties. Both
          projects will help build a stronger international left. In both cases
          we should put greater emphasis on developing ties to rank-and-file and
          worker-led parties and movements than on top-down connections. In
          particular, U.S. socialists should emphasize connections with Latin
          American comrades.
        </p>
        <p>
          2. Develop and communicate internationalist perspectives within
          working-class movements at home. DSA should incorporate
          internationalism into our labor and electoral work through discussions
          with fellow activists, and, where broad interest exists, organize for
          agitation or solidarity actions by unions and socialists in office.
          U.S. socialists should oppose war — direct or proxy, initiated by the
          U.S. or other regional imperialist powers — and advance an
          internationalist, democratic perspective as outlined above.
        </p>
        <p>
          In our labor and electoral work, socialists should prioritize building
          connections with local immigrant activists, especially those connected
          to left movements in their countries of origin.
        </p>
      </Element>
      <Element
        name="6. Building a Democratic DSA"
        id="6. Building a Democratic DSA"
      >
        <div className="h-wrapper six">
          <h2>6. Building a Democratic DSA</h2>
        </div>
        <h3>6.1 Perspectives on DSA</h3>
        <div className="focus">
          <p>
            Since its rebirth and membership explosion over the course of 2016
            to 2020, DSA has become the largest socialist organization in the
            United States and the first choice for most people looking to turn
            socialist ideals into organized activity. Bread & Roses affirms the
            need for socialist organization and advocates for a vision for DSA
            that would allow it to fulfill its promise of becoming a real
            political force and the home of socialism in this country. To that
            end, DSA should adopt the following perspectives.
          </p>
        </div>
        <p>
          <strong>1. DSA should aim to change the world.</strong> The central
          aim of socialists and their organization should be to build a
          working-class movement that can materially challenge the power of the
          ruling class, ultimately winning a democratic socialist society.
        </p>
        <p>
          <strong>2. DSA should be democratic.</strong> The struggle for
          democratic socialism is the struggle for working-class
          self-emancipation. That means the organizations we create to advance
          the cause of socialism, including DSA, must be built around democracy
          and member control. Elected leadership and members should have the
          power to effectively direct our collective resources and staff time
          toward democratically-decided priorities and important political work.
        </p>
        <p>
          <strong>3. DSA should remain a “big tent” organization.</strong> DSA’s
          “big tent” or multi-tendency character is a hugely positive
          development for socialism in the U.S., allowing anyone interested in
          democratic socialist politics to join and participate in organized
          political work.
        </p>
        <p>
          <strong>4. DSA should be politically independent.</strong>{" "}
          Historically, DSA followed the model of any other junior partner in
          the Democratic Party sphere of influence, relying on relationships
          between influential “grass-tops” leaders within DSA, NGOs, and union
          bureaucracies. Instead, DSA should be a politically independent,
          member-led organization that takes a grassroots approach to politics.
        </p>
        <h4>6.1.1 Obstacles to Building DSA</h4>
        <p>
          Since the end of the 2020 Democratic primary and the passing of the
          Trump era, DSA’s meteoric growth has stalled and perhaps even
          reversed. In the face of internal disorganization that often makes it
          almost impossible to meaningfully intervene in national politics, we
          must come to terms with a number of serious structural challenges in
          the organization. These challenges make it difficult for DSA to offer
          a coherent political vision and make reform in the organization
          difficult or impossible to achieve in the short term. They include:
        </p>
        <p>
          <strong>
            1. Lack of democratic oversight over the national office:
          </strong>{" "}
          We believe the political priorities of the national director and lack
          of real democratic decision-making over key resources like staff time
          and money are major threats to the organization. Decisions around
          priorities and resource management are completely opaque to members
          and even difficult for elected leaders to discern. Moreover, DSA’s
          number one expense, staff time, is effectively outside the purview of
          democratic control or oversight. Reflecting its pre-2016 NGO-style
          organization, even elected leaders struggle to direct the organization
          in meaningful ways. Most proposals adopted at biannual national DSA
          conventions are ignored by the national director, who instead chooses
          which projects the staff focuses on. Despite being portrayed as merely
          “administrative” and not “political,” DSA’s national director has
          great influence on the political and organizational priorities of DSA.
        </p>
        <div className="img-wrapper">
          <img src={"/dsa.jpg"} alt="DSA" />
        </div>
        <p>
          In contrast to the paltry resources spent on political work, an
          enormous amount of time, money, and energy is put into administrative
          functions, such as the handling of grievances. The majority of staff
          is hired for administrative positions, while the “political” staff is
          mostly field staff, who focus the bulk of their time assisting
          chapters with administrative skills-training, rather than helping
          chapters to do political work that could make an impact in the world.
          Criticisms of the lack of democratic oversight in DSA are
          misrepresented as “anti-staff” harassment by the national director and
          some groups in DSA.
        </p>
        <p>
          <strong>2. An ineffective National Political Committee:</strong> The
          NPC has been relegated to administering large internal projects that
          could be led by staff. As a result, NPC members are left with very
          little time for political leadership. The priorities of the
          organization are often those of the national director, with little
          regard either for the preferences of NPC members or the priorities
          voted at convention. In order to push through action on democratic
          decisions, NPC members often have to fight for months in meetings and
          by email, and perhaps even take their campaign public, for their
          project to be allocated resources that were decided on by the national
          convention. This dynamic exacerbates the de-politicization of the
          national convention, in which NPC candidates contend in often
          uncompetitive elections with unclear political lines and members see
          little value in debates over political direction.
        </p>
        <p>
          ​​<strong>3. The class and social composition of our members:</strong>{" "}
          While DSA members as a whole are members of the working class in the
          sense that they are employees, not employers, they reflect particular
          segments of the workforce: highly educated, disproportionately white,
          and with some degree of autonomy at work. In many instances, DSA still
          doesn’t feel relevant to large segments of the working class, and it
          can be difficult for our organizers working in the labor movement to
          bring coworkers into the organization. While DSA has provided many
          members with a shared purpose and camaraderie, it has also struggled
          with a subcultural social scene, especially online.
        </p>
        <p>
          <strong>4. Contradictions in the “big tent”:</strong> DSA’s “big tent”
          has been a boon to the socialist movement and a source of creative
          tensions. At the same time, it presents enormous obstacles to
          developing a coherent political strategy. Any attempt by a minority
          faction to centralize power within the organization has proven
          dangerously destabilizing. The ongoing controversy over DSA-backed
          Congressmember Jamaal Bowman’s support for Israeli military aid — and
          the NPC’s handling of this controversy — highlights growing challenges
          in the organization.
        </p>
        <h3>6.2 Tasks for DSA</h3>
        <p>
          At the chapter level, DSA members should maximize our real engagement
          with the working class by prioritizing external work to build class
          power through labor work, electoral campaigns, socialist night schools
          and other political education projects, local DSA publications, and
          the opening of DSA chapter offices and the hiring of local staff. B&R
          members will also take an active role in building state-level DSA
          organizations where possible.
        </p>
        <p>
          Nationally, B&R will continue to promote our vision of an independent,
          democratic, member-led socialist organization in DSA. Much of DSA’s
          currently misspent national resources should be shifted away from
          administrative work and towards electoral work, labor work, political
          education, democratic socialist communications, YDSA, and
          international solidarity — with the aim of building a majority within
          the socialist movement that rejects the top-down machine politics of
          Democratic Party-aligned organizations. DSA should reorganize internal
          campaigns and committees to encourage more collaboration and less
          siloing of work.
        </p>
        <p>
          B&R will work with other DSA members to advance organizational reforms
          that will make DSA more effective and politically coherent. DSA needs
          an expanded political leadership structure, democratically accountable
          to membership, with a stronger role for elected leadership in
          overseeing staff and organizational resources. DSA needs positive
          reforms to repair our fractious political culture, such as a more
          effective grievance system, better enforcement of the membership code
          of conduct, and new avenues for open political debate.
        </p>
        <div className="pullquote">
          <p>
            “Nationally, B&R will continue to promote our vision of an
            independent, democratic, member-led socialist organization in DSA.”
          </p>
        </div>
        <p>
          We will work in DSA to build its ability to fulfill its function as a
          socialist organization. This means building a layer of committed
          socialist cadre through comprehensive political education on socialist
          history and theory, putting forward clear socialist political
          perspectives, and developing rich and deep social ties between
          members.
        </p>
        <p>
          At both the chapter and national level we should also institute the
          practice of drafting tasks and perspectives documents, to focus the
          organization on democratically deciding questions of political
          strategy. DSA should prioritize campaigns and organizing tasks which
          make more socialists, build the power of DSA, and build the power of
          the working class.
        </p>
      </Element>
      <Element
        name="7. Organizing a Democratic Socialist Student Movement"
        id="7. Organizing a Democratic Socialist Student Movement"
      >
        <div className="h-wrapper seven">
          <h2>7. Organizing a Democratic Socialist Student Movement</h2>
        </div>
        <h3>7.1 Perspectives on YDSA and Student Organizing</h3>
        <div className="focus">
          <p>
            Growing up in the context of economic and political crisis has led
            young people in the United States to be far more radical than other
            sections of society. In the past few years, more than 200 YDSA
            chapters and organizing committees have sprung up across the country
            thanks to a combination of political conditions and concerted work.
          </p>
        </div>
        <p>
          Young people become politicized far more quickly and deeply than other
          segments of society and are far more receptive to making the necessary
          practical commitments that flow from our politics. Their futures are
          far more open than older people with established careers and lives,
          making them prime recruits for the rank-and-file strategy. Students
          are also used to being part of campus groups, being asked to do
          things, and getting involved in leadership. High-turnover rates due to
          graduation and smaller chapter sizes creates an emphasis on leadership
          development. Within a few months of joining, a new recruit might be a
          chapter leader with skills to conduct one-on-ones, run meetings, and
          organize political education. Campuses also tend to be a place where
          we’ve been able to successfully recruit cadre from more
          socioeconomically-diverse backgrounds.
        </p>
        <p>
          YDSA chapters and national committees have supported young socialists
          by providing political education and training in organizing skills,
          and helped some YDSAers build struggles on their campuses and new
          student-worker unions. YDSA mentors have played an integral role in
          recruiting and training up a new layer of young socialist leaders.
          Labor plays a far more central role in YDSA than DSA — labor education
          is a central part of YDSA political education, many YDSAers have taken
          rank-and-file union jobs or are planning to, are salting or planning
          to, and have been a core leadership layer of the newly emergent
          student-worker unions around the country. YDSAers also recognize
          connecting with and supporting campus labor activity as an important
          responsibility as campus socialists.
        </p>
        <div className="pullquote">
          <p>
            “Within a few months of joining, a new recruit might be a chapter
            leader with skills to conduct one-on-ones, run meetings, and
            organize political education.”
          </p>
        </div>
        <p>
          Despite its strengths, YDSA has some important limitations. YDSA lacks
          autonomy and doesn’t receive significant resources from DSA. While
          YDSA is ostensibly a member-run democratic organization, its
          nationally elected leaders aren’t empowered to lead. Until this year,
          YDSA did not have its own independent budget and requesting funds for
          projects from the DSA Budget Committee was an arduous process. In
          2022, the NPC granted YDSA an independent budget: a measly $15,000.
          The YDSA National Coordinating Committee (NCC) has limited ability to
          direct staff time toward membership-determined priorities and
          committees. Staff instead is primarily self-directed based on what
          they view to be priorities. YDSA is also severely understaffed and
          efforts to increase staffing have been consistently stonewalled.
        </p>
        <p>
          The second limitation is a general lack of experience in working in
          and building broader movements and organizations. This leads to an
          inward-focused tendency of building national committees and local
          chapters for their own sake, rather than broader movement work
          intervening in the world. There is also a tendency for experienced
          organizers to be recruited to run the national organization and take
          on various administrative duties in national committees, diverting
          their attention and time away from building local struggles on campus.
          Despite the climate crisis, attacks on abortion, and other major
          issues that young people care deeply about, there are few if any mass
          movements or organizations for students and young people to
          participate in. In addition to strengthening YDSA, the socialist pole
          on campus, Bread & Roses YDSAers must also figure out how to build
          struggles and organizations beyond the group of young people who are
          already convinced of the need for socialism and socialist
          organization.
        </p>
        <p>
          Due to these limitations, thus far the organization has been unable to
          provide systematic support for campaign work or YDSAers seeking to
          enter into the labor movement as rank-and-filers. Meanwhile the
          majority of students on campus remain apolitical or don’t have clear
          ways to get involved in radical politics.
        </p>
        <h3>7.2 Tasks for YDSA and Student Organizing</h3>
        <p>In light of this, there are three major tasks for YDSAers.</p>
        <p>
          First, socialists must help build campus struggles that allow students
          to experience collectively fighting over the conditions of their own
          life in mass democratic movements. These struggles will sometimes be
          housed in YDSA, and will sometimes be housed in broader movements and
          organizations that YDSA will organizationally support and YDSAers will
          help lead — such as student-worker unions. We want to put students in
          motion, regardless of their self-identification as socialists or
          willingness to join socialist organization. Through the process of
          engaging in mass struggle alongside socialists, we can win students
          over to our ideas and socialist organization. Over the coming year,
          YDSA should prioritize building broad student-worker unions around the
          country, building labor struggles on campus (through direct
          involvement where possible and labor solidarity elsewhere), and
          helping to build broad movements in defense of abortion rights and
          other civil liberties.
        </p>
        <div className="img-wrapper">
          <img src={"/march.jpg"} alt="march" />
        </div>
        <p>
          Second, YDSA should aim to produce lifelong socialist organizers —
          cadre. We should prioritize systematic mentorship and organizer
          development, training up the next generation of socialist leaders,
          preparing many of them for a lifetime of socialist organizing and
          labor activism as rank-and-file workers in strategic industries. Cadre
          will be developed through a combination of participating in and
          leading struggles on campus and in their communities, rigorous
          one-on-one mentorship, and political education. We should be directing
          cadre toward short-term salting projects while they are students and
          toward long-term careers as rank-and-file union members in strategic
          industries.
        </p>
        <p>
          Third, YDSA should act as a socialist pole on our campuses, convincing
          students to become democratic socialists and join socialist
          organization. YDSA should work to win students over to the idea that a
          democratic socialist reorganization of society is necessary to address
          the permanent social injustices and crises created by capitalism and
          that such a society can only be won as an act of working-class self
          emancipation. We must convince students interested in social justice
          that their tasks are to build a democratic, class-struggle labor
          movement, mass democratic and disruptive social movements, and an
          independent workers party.
        </p>
        <div className="end-image">
          <img src={"/bnr-glitch.jpg"} alt="B&R logo" />
        </div>
      </Element>
    </div>
  </Layout>
)

export default Tasks
